<div class="container">
<mmp5-breadcrumb></mmp5-breadcrumb>

<section class="mm-box-container generalData">
  <div class="container-border--companyName" *ngFor="let user of userInfo">
    <header class="mm-box-header">{{user.companyName}}</header>

    <div class="row">

      <div class="col">
        <div *ngIf="altered" class="alert-adjust">
          <mm-alert [type]="alertClass" [text]="alertText"></mm-alert>
        </div>
      </div>
    </div>

    <div>
      <div>
        <div class="box-data--adjust box-clienteatv">
          <div class="box-clienteatv--margin" *ngIf="!user?.licenseValidatedDate; else noLicenseValidated">
            <p><strong> Chave de acesso deste cliente:</strong> {{user.licenseKey}}</p>
            <p *ngIf="user.activationLink"><strong> Chave de ativação: {{user.activationLink}} </strong></p>
          </div>
          <ng-template #noLicenseValidated>
            <div class="box-clienteatv--margin">
              <p><strong> Esta conta de cliente foi verificada em </strong> {{user?.licenseValidatedDate | date:
                'dd/MM/yyyy'}}</p>
            </div>
          </ng-template>

          <div class="client-status" *ngIf="!user.totalBlock; else elseNoActive">
            <div class="client-status--adjuststyle">
              <p>
                <!-- <i class="fa fa-check-circle faicon-checkcircle"> </i>  -->
                <mmp5-mm-icons class="faicon-checkcircle" [icon]="['fa-solid','fa-circle-check']"></mmp5-mm-icons>
                O Status deste cliente é <strong>Ativo</strong>
              </p>
            </div>

            <div class="text-right text-right--btn">
              <button class="btn btn-primary" (click)="blockClient($event)">Efetuar bloqueio imediato</button>

              <mmp-tooltip
                [text]="'Atenção: O bloqueio completo interrompe todas as seções de usuários logados e bloqueia a utilização de todos os serviços da ferramenta.'"
                [tooltipClass]="'tooltip-default'"
                [ariaLabel]="'Ícone que mostra uma caixa de texto'"
                [tooltipPosition]="'above'" [class]="'faicon-questioncircle tooltip'"></mmp-tooltip>

            </div>
          </div>

        </div>

        <ng-template #elseNoActive>
          <div class="row client-status" *ngFor="let block of blockEvents">
            <div class="col-md-8">
              <p>
                <!-- <i class="fa fa-exclamation-triangle"> </i> -->
                <mmp5-mm-icons class="exclamation-triangle" [icon]="['fa-solid','fa-triangle-exclamation']"></mmp5-mm-icons>
                O status deste cliente é: <strong> BLOQUEADO</strong> Motivo: {{block.reason.description}} | Data do
                bloqueio: {{block.creationDate | date: 'dd/MM/yyyy'}}
              </p>
            </div>
            <div class="col-md-4 text-right text-right--btn">
              <button class="btn btn-primary" (click)="unblockClient($event)">Desbloquear imediatamente</button>
              <mmp-tooltip [text]="'Atenção: o desbloqueio completo ativa todas as sessões de usuários logados e permite a utilização de todos os serviços da ferramenta.'"
                [tooltipClass]="'tooltip-default'" [ariaLabel]="'Ícone que mostra uma caixa de texto'"
                [tooltipPosition]="'above'" [class]="'faicon-questioncircle tooltip'"></mmp-tooltip>
            </div>
          </div>
        </ng-template>

        <div class="box-data-section box-data--padding">
          <div class="row">
            <div class="col-md-7">
              <div class="box-data">
                <h5>Dados da Empresa</h5>

                <div class="box-data--company">
                  <p class="p-strong"><strong>Nome do Cliente</strong></p>
                  <p>{{user.companyName}}</p>

                  <p class="p-strong"><strong>CNPJ</strong></p>
                  <p>{{user.cnpj}}</p>

                  <p class="p-strong"><strong>Tipo de Licenciamento</strong></p>
                  <p>{{user.licenseType | licence}}</p>

                  <p class="p-strong"><strong>Quantidade de Licenças contratadas</strong></p>
                  <p>Mínimo: {{user.qtMin}} Máximo: {{user.qtMax}} - Bloqueio parcial:
                    <span *ngIf="user.autoPartialBlock; else elseNo"> Sim</span>
                    <ng-template #elseNo><span> Não</span></ng-template>
                  </p>

                  <p class="p-strong"><strong>Vigência do Contrato</strong></p>
                  <p>
                    Início: {{user.initialDate | date: 'dd/MM/yyyy'}}
                    Término: {{user.finalDate | date: 'dd/MM/yyyy'}}
                  </p>
                  <p class="p-strong"><strong>Observação</strong></p>
                  <p>{{user?.observations}}</p>
                </div>

              </div>
            </div>

            <div class="col-md">
              <div class="box-data">
                <h5>Contatos do Responsável</h5>

                <div class="box-data--contact">
                  <p class="p-strong"><strong>Nome do contato responsável</strong></p>
                  <p>{{user.name}}</p>
                  <p class="p-strong"><strong>E-mail do contato do responsável</strong></p>
                  <p>{{user.email}}</p>
                  <p class="p-strong"><strong>Telefone do contato do responsável</strong></p>
                  <p>{{user.phone}}</p>
                </div>
              </div>

              <div class="box-data box-data--report">
                <h5>Contatos para Relatórios</h5>

                <div class="box-data--contact">
                  <div *ngFor="let mail of user.reportEmails">
                    <p class="p-strong"><strong>E-mail do contato responsável</strong></p>
                    <p>{{mail.email}}</p>

                    <p class="p-strong"><strong>Periodicidade do envio de relatórios</strong></p>
                    <p>{{mail.type | reportTime }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="content" *ngIf="user?.modules.length > 0">
            <mm-list-modules #modules [activeClientsModules]="user?.modules" [type]="'view'">
            </mm-list-modules>
          </div>

          <div class="d-flex justify-content-between mb-3">
            <div>
              <a routerLink="/clients/edit/{{user?.id}}" class="btn btn-primary">
                Editar Cliente
              </a>
            </div>
            <div>
              <button (click)="createEmbeddingIndex()" class="btn btn-primary ms-2">Criar index vetorial (Alice)</button>
              <button (click)="deleteMedias()" class="btn btn-primary ms-2"> Excluir mídias</button>
              <button (click)="indexUsers()" class="btn btn-primary ms-2"> Indexar usuários</button>
              <button (click)="indexCMS()" class="btn btn-primary ms-2"> Indexar CMS</button>
              <button (click)="cleanCache()" class="btn btn-primary ms-2"> Limpar cache</button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="mm-box-container">
  <header class="mm-box-header">Configuração de Tamanho Maximo de Upload de Mídia</header>
  <div class="mm-box-content">
    <mm-alert *ngIf="maxFileMessage" [type]="'success'" [text]="'Configuração salva com sucesso!'"></mm-alert>
    <div class="d-flex justify-content-between mb-1">
      <p>Definir configurações de Tamanho Maximo de Upload de Mídia</p>
      <button class="btn btn-primary" (click)="addMaxFileSizeConfig()">Adicionar nova configuração</button>
    </div>
    <div class="mm-box-container dark p-2 row" *ngFor="let item of maxFileSizeConfig.get('items')?.controls; let i = index">
      <div class="col-12 d-flex justify-content-end">
        <button class="btn btn-primary" (click)="removeMaxSizeConfig(i)">Remover configuração</button>
      </div>
      <div class="col-12 col-lg-8">
        <h5 class="mt-1">Content Type</h5>
        <mmp5-fieldset-haruki
          [formControl]="item.get('contentType')"
          [key]="'contentType-'+i"
          [label]="'Content Type'">
        </mmp5-fieldset-haruki>
      </div>
      <div class="col-12 col-lg-4">
        <h5 class="mt-1">Tamanho Maxímo (MB)</h5>
        <mmp5-fieldset-haruki
          [formControl]="item.get('maxSize')"
          [key]="'maxSize-'+i"
          [type]="'number'"
          [label]="'Tamanho'">
        </mmp5-fieldset-haruki>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-lg-4">
        <h5 class="mt-1">Tamanho Máximo Outras Mídias</h5>
        <mmp5-fieldset-haruki
          [formControl]="maxFileSizeConfig.get('otherContentTypeMaxSize')"
          [key]="'json'"
          [label]="'Tamanho (MB)'"
          [type]="'number'"
          [height]="'350px'">
        </mmp5-fieldset-haruki>
      </div>
    </div>
    <div class="d-flex justify-content-end mt-1">
      <button class="btn btn-primary" (click)="saveMaxFileSizeConfig()">Salvar Configuração</button>
    </div>
  </div>
</section>

<section class="mm-box-container">
  <header class="mm-box-header">Configuração de Service Account Google para uso de I.A.</header>
  <div class="mm-box-content">
    <mm-alert *ngIf="aliceMessage" [type]="'success'" [text]="'Configuração salva com sucesso!'"></mm-alert>
    <p>Definir configurações do Service Account Google para uso da Vertex AI</p>
    <h5 class="mt-1">Project ID</h5>
    <mmp5-fieldset-haruki
      [formControl]="aliceGoogleConfigForm.get('projectId')"
      [key]="'projectId'"
      [label]="'Project Id'">
    </mmp5-fieldset-haruki>
    <h5 class="mt-1">Location</h5>
    <mmp5-fieldset-haruki
      [formControl]="aliceGoogleConfigForm.get('location')"
      [key]="'location'"
      [label]="'Location'">
    </mmp5-fieldset-haruki>
    <h5 class="mt-1">JSON</h5>
    <mmp5-fieldset-haruki
      [formControl]="aliceGoogleConfigForm.get('json')"
      [key]="'json'"
      [label]="'JSON'"
      [type]="'textarea'"
      [height]="'350px'">
    </mmp5-fieldset-haruki>
    <div class="d-flex justify-content-end mt-1">
      <button class="btn btn-primary" (click)="saveAliceGoogleConfig()">Salvar Configuração</button>
    </div>
  </div>
</section>

<section class="mm-box-container">
  <header class="mm-box-header">Configuração de Service Account Google para storage</header>
  <div class="mm-box-content">
    <mm-alert *ngIf="storageMessage" [type]="'success'" [text]="'Configuração salva com sucesso!'"></mm-alert>
    <p>Definir configurações do Service Account Google para uso de storage</p>
    <h5 class="mt-1">Project ID</h5>
    <mmp5-fieldset-haruki
      [formControl]="storageGoogleConfigForm.get('projectId')"
      [key]="'storage_projectId'"
      [label]="'Project Id'">
    </mmp5-fieldset-haruki>
    <h5 class="mt-2">Location</h5>
    <mmp5-fieldset-haruki
      [formControl]="storageGoogleConfigForm.get('location')"
      [key]="'storage_location'"
      [label]="'Location'">
    </mmp5-fieldset-haruki>
    <h5 class="mt-2">JSON</h5>
    <mmp5-fieldset-haruki
      [formControl]="storageGoogleConfigForm.get('json')"
      [key]="'storage_json'"
      [label]="'JSON'"
      [type]="'textarea'"
      [height]="'350px'">
    </mmp5-fieldset-haruki>
    <div class="d-flex align-items-center">
      <h5 class="mt-2 mr-2">Usar CDN: </h5>
      <mmp5-fieldset-haruki
        [formControl]="storageGoogleConfigForm.get('useCdn')"
        [key]="'storage_useCdn'"
        [type]="'switch'"
        [checked]="storageGoogleConfigForm.get('useCdn').value">
      </mmp5-fieldset-haruki>
    </div>
    <h5 class="mt-2">Url da CDN</h5>
    <mmp5-fieldset-haruki
      [formControl]="storageGoogleConfigForm.get('cdnUrl')"
      [key]="'storage_cdnUrl'"
      [label]="'CDN Url'">
    </mmp5-fieldset-haruki>
    <h5 class="mt-2">Storage Private Url</h5>
    <mmp5-fieldset-haruki
      [formControl]="storageGoogleConfigForm.get('storagePrivateUrl')"
      [key]="'storage_storagePrivateUrl'"
      [label]="'Storage Private Url'">
    </mmp5-fieldset-haruki>
    <h5 class="mt-2">Nome do Bucket</h5>
    <mmp5-fieldset-haruki
      [formControl]="storageGoogleConfigForm.get('bucketName')"
      [key]="'storage_bucketName'"
      [label]="'Bucket Name'">
    </mmp5-fieldset-haruki>
    <h5 class="mt-2">Credentials Scoped</h5>
    <mmp5-fieldset-haruki
      [formControl]="storageGoogleConfigForm.get('credentialsScoped')"
      [key]="'storage_credentialsScoped'"
      [label]="'Credentials Scoped'">
    </mmp5-fieldset-haruki>
    <div class="d-flex justify-content-end mt-1">
      <button class="btn btn-primary" (click)="saveGoogleStorageConfig()">Salvar Configuração</button>
    </div>
  </div>
</section>

<section class="mm-box-container alice" *ngIf="hasAlice">
  <header class="mm-box-header">Configurações de Chaves e Valores (Alice)</header>
  <div class="mm-box-content">
   <p>{{ 'alice.config.keyValueConfigDesc' | translate }}</p>
    <h3>{{ 'alice.config.type' | translate }}</h3>
    <p>{{ 'alice.config.typeDesc' | translate }}</p>
    <ul>
      <li><strong>{{ 'alice.config.systemMessage' | translate }}</strong>: {{ 'alice.config.systemMessageDesc' | translate }}</li>
      <li><strong>{{ 'alice.config.externalConnector' | translate }}</strong>: {{ 'alice.config.externalConnectorDesc' | translate }}</li>
      <li><strong>{{ 'alice.config.model' | translate }}</strong>: {{ 'alice.config.modelDesc' | translate }}</li>
      <li><strong>{{ 'alice.config.embedding' | translate }}</strong>: {{ 'alice.config.embeddingDesc' | translate }}</li>
    </ul>
    <h3>{{ 'alice.config.parameters' | translate }}</h3>
    <ul>
      <li><strong>{{ 'alice.config.name' | translate }}</strong>: {{ 'alice.config.parametersKey' | translate }}</li>
      <li><strong>{{ 'alice.config.value' | translate }}</strong>: {{ 'alice.config.parametersValue' | translate }}</li>
    </ul>
   <mm-alice-keys-config [type]="'LICENSE'" [forms]="forms" [resetForms]="aliceConfigTypes.value" (removeForm)="removeForm($event)"></mm-alice-keys-config>

   <!-- <section class="mm-box-container key-value-config"> -->
     <div class="mm-box-content">
       <h5>Resetar parâmetros da Alice</h5>
       <div class="row">
         <div class="col-6">
           <p>Resetar todos os parâmetros da Alice</p>
           <div class="d-flex justify-content-end full-width">
             <button (click)="resetAllAliceConfigs()" class="btn btn-primary">Resetar todos os parâmetros</button>
           </div>
         </div>
         <div class="col-6">
           <p>Resetar um parâmetro específico da Alice.</p>
           <mmp5-fieldset-haruki
           [formControl]="aliceConfigTypes"
           [key]="'aliceConfigTypes'"
           [label]="'Escolha a configuração'"
           [autocomplete]="'off'"
           [selectOptions]="aliceConfigs">
           </mmp5-fieldset-haruki>
           <div class="d-flex justify-content-end">
             <button (click)="resetSpecifiedAliceConfig()" class="btn btn-primary mt-2">Resetar parâmetro escolhido</button>
           </div>
         </div>
        <div class="col-12 col-md-6">
          <h5>Agentes</h5>
          <button class="btn btn-primary" (click)="recreateAgents()">Apagar e criar novamente todos os Agentes.</button>
          <span *ngIf="createdAgents">Agentes Recriados!</span>
        </div>
        <div class="col-12 col-md-6">
          <h5>Converter conversas atuais para projeto Master</h5>
          <button class="btn btn-primary" (click)="convertChats()">Converter</button>
          <span *ngIf="chatConverted">Conversar convertidas!</span>
        </div>
       </div>
     </div>
   <!-- </section> -->
  </div>

  <section class="mm-box-footer d-flex justify-content-end mt-1">
    <button class="btn btn-primary" (click)="saveConfig()">Salvar Chaves</button>
  </section>
  <section class="mm-box-footer d-flex justify-content-start align-items-center mt-1">
    <h5 class="mt-2 mr-2">Ativar a extração de áudio em vídeo: </h5>
    <mmp5-fieldset-haruki
    [formControl]="convertVideo"
    [key]="'convert_video'"
    [type]="'switch'"
    [checked]="convertVideo.value">
  </mmp5-fieldset-haruki>
  </section>
</section>

<section class="mm-box-container generalData" *ngIf="!notAllowed">
  <header class="mm-box-header">Relatórios do cliente</header>
  <div class="mm-box-content">
    <div class="row container-border--report--adjust">
        <div class="row">
          <div class="col-12">
            <button class="btn btn-primary" (click)="showReports()">
              <mmp5-mm-icons [icon]="['fa-solid','fa-chart-column']"></mmp5-mm-icons>
              {{ 'dashboard.view.reportsName' | translate}}
          </button>
          </div>
      </div>
    </div>
  </div>
</section>

<div class="container margin-border card-section" *ngIf="!notAllowed">
<div class="row card-style" *ngFor="let user of userInfo">
  <div class="col-md-12 col-lg-4">
    <section class="mm-box-container totalUsers">
      <header class="mm-box-header">
        <h6 class="m-0">Usuários logados</h6>
      </header>
      <div class="mm-box-content text-center">
        <div class="mm-box-content">

          <div class="container">
            <p>{{user.totalCurrentSessions}}</p>
          </div>

        </div>
      </div>
    </section>
  </div>

  <div class="col-md-12 col-lg-4">
    <section class="mm-box-container totalUsers">
      <header class="mm-box-header">
        <h6 class="m-0">Usuários ativos</h6>
      </header>
      <div class="mm-box-content text-center">
        <div class="mm-box-content">
          <!-- <mmp5-graph></mmp5-graph> -->
          <p>{{user?.activeUsers || 0}}</p>
        </div>
      </div>
    </section>
  </div>

  <div class="col-md-12 col-lg-4">
    <section class="mm-box-container totalUsers">
      <header class="mm-box-header">
        <h6 class="m-0">Quantidade total de páginas</h6>
      </header>
      <div class="mm-box-content text-center">
        <div class="mm-box-content">

          <!-- <mmp5-graph></mmp5-graph> -->
          <p>{{user?.totalPages || 0}}</p>
        </div>
      </div>
    </section>
  </div>
  <p-sidebar [(visible)]="reports" position="right" [showCloseIcon]="false" [style]="{padding: '0px', border: '10px', width: '60rem'}" (onHide)="clearReportForm()">
    <div class="sidebar-header">
        <h5>
            {{ 'dashboard.view.reportsName' | translate}}
        </h5>
        <mmp5-mm-icons class="close" (click)="reports = false" [icon]="['icon','icon-fechar']"></mmp5-mm-icons>
    </div>

    <div class="sidebar-content">
        <p>{{ 'dashboard.view.selectBelow' | translate}}</p>
        <mmp5-fieldset-haruki
          [formControl]="reportType"
          [key]="'reportType'"
          [autocomplete]="'off'"
          [selectOptions]="reportTypeOptions">
        </mmp5-fieldset-haruki>

            <ng-template
                [ngIf]="(reportType?.value == 'pageAccess' ||
                        reportType?.value == 'sessions' ||
                        reportType?.value == 'alteredPages' ||
                        reportType?.value == 'ratingFeedback' ||
                        reportType?.value == 'periodPermission' ||
                        reportType?.value == 'flowsurveyNodeCreation')
                        && reportForm"
            >
                <h4 class="mt-1">{{('dashboard.view.reports.'+reportType?.value) | translate}}</h4>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <h5>{{ 'dashboard.view.dataStart' | translate}} *</h5>
                        <mmp5-fieldset-haruki
                            [formControl]="reportForm.get('initialDate')"
                            [key]="'initialDate-report'"
                            [autocomplete]="'off'"
                            [type]="'date'">
                        </mmp5-fieldset-haruki>
                    </div>
                    <div class="col-12 col-lg-6">
                        <h5>{{ 'dashboard.view.dataEnd' | translate}} *</h5>
                        <mmp5-fieldset-haruki
                            [formControl]="reportForm.get('finalDate')"
                            [key]="'finalDate-report'"
                            [autocomplete]="'off'"
                            [type]="'date'">
                        </mmp5-fieldset-haruki>
                    </div>
                </div>
                <p class="sub">{{ 'dashboard.view.itCannot3Mon' | translate}}</p>
                <div *ngIf="reportType?.value != 'alteredPages' && reportType?.value != 'ratingFeedback' && reportType?.value != 'periodPermission' && reportType?.value != 'flowsurveyNodeCreation'">
                  <h5>{{ 'dashboard.view.filterAccess' | translate}}</h5>
                  <mmp5-search-suggest
                      class="user__search"
                      [placeholder]="'Buscar usuário'"
                      [fieldset]="true"
                      [formControl]="userSearch"
                      [searchFn]="searchUser.bind(this)"
                      (suggestionSelect)="setUserId($event)"
                      [type]="'search'">
                  </mmp5-search-suggest>

                  <div class="list-items" *ngIf="userS">
                    <ul class="list-ul">
                        <li class="list-item">
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{userS.name}}</span>
                                <mmp5-mm-icons [icon]="['icon','icon-excluir']" (click)="removeUser()" class="text-danger icon-remove"></mmp5-mm-icons>
                            </div>
                        </li>
                    </ul>
                  </div>
                </div>
            </ng-template>

            <ng-template [ngIf]="(reportType?.value == 'sessionsPeak' || reportType?.value == 'activeUsersPeak') && reportForm">
                <h4 class="mt-1 mt-0">{{('dashboard.view.reports.'+reportType?.value) | translate}}</h4>
                <p>{{'dashboard.view.reports.month' | translate}}</p>
                <mmp5-fieldset-haruki
                    [formControl]="reportForm.get('initialDate')"
                    [key]="'initialDate-peak'"
                    [mask]="'00/0000'">
                </mmp5-fieldset-haruki>
            </ng-template>

            <ng-template [ngIf]="reportType?.value == 'forms' && reportForm">
                <h4 class="mt-1">{{ 'dashboard.view.fillForm' | translate}}</h4>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <h5>{{ 'dashboard.view.dataStart' | translate}} *</h5>
                        <mmp5-fieldset-haruki
                            [formControl]="reportForm.get('initialDate')"
                            [key]="'initialDate-report'"
                            [autocomplete]="'off'"
                            [type]="'date'">
                        </mmp5-fieldset-haruki>
                    </div>
                    <div class="col-12 col-lg-6">
                        <h5>{{ 'dashboard.view.dataEnd' | translate}} *</h5>
                        <mmp5-fieldset-haruki
                            [formControl]="reportForm.get('finalDate')"
                            [key]="'finalDate-report'"
                            [autocomplete]="'off'"
                            [type]="'date'">
                        </mmp5-fieldset-haruki>
                    </div>
                    <div class="col-12">
                        <h5>{{ 'dashboard.view.formType' | translate}}</h5>
                        <mmp5-fieldset-haruki
                        [formControl]="formsType"
                        [key]="'formsType'"
                        [selectOptions]="formsTypeOptions">
                        </mmp5-fieldset-haruki>
                    </div>
                    <div *ngIf="formsType.value" class="col-12">
                        <h5>{{formsType.value == 'templateForm' ? ('dashboard.view.templateForm' | translate) : ('dashboard.view.pageContaining' | translate)}}</h5>
                        <mmp5-fieldset-haruki
                        [formControl]="formType"
                        [key]="'formType'"
                        [selectOptions]="formTypeOptions">
                        </mmp5-fieldset-haruki>
                    </div>
                </div>
            </ng-template>

            <ng-template [ngIf]="jasperReportParams && jasperReportParams.length > 0">
              <mmp-jasper-report-fields [jasper-params]="jasperReportParams" (jasper-data)="getJasperReportData($event)"></mmp-jasper-report-fields>
            </ng-template>

            <div class="d-flex justify-content-end">
                <button
                    *ngIf="!loadReport"
                    [disabled]="!reportType?.value ? true : reportType?.value && reportType?.value != 'forms' ? reportForm?.invalid : (reportForm?.invalid || formsType.invalid || formType.invalid)"
                    class="btn btn-primary mt-1"
                    (click)="downloadReport()">
                    <mmp5-mm-icons [icon]="['fa-solid','fa-download']"></mmp5-mm-icons>
                    {{ 'dashboard.view.downloadReport' | translate}}
                </button>
                <button class="btn btn-primary mt-1" *ngIf="loadReport" [disabled]="true">
                    <p-progressSpinner [style]="{width: '20px', height: '20px'}" strokeWidth="8" animationDuration=".5s"></p-progressSpinner>
                </button>
            </div>
    </div>
</p-sidebar>
</div>

import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'mmp5-error-validation',
  templateUrl: './error-validation.component.html',
  styleUrls: ['./error-validation.component.scss']
})
export class ErrorValidationComponent implements OnInit, OnChanges {

  @Input() field: UntypedFormControl;
  @Input() onlyIfTouched = false;

  errorsKeys = [];
  status = 'VALID';

  constructor() { }

  ngOnInit() {
    this.bind();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.field) {
      this.bind();
    }
  }

  bind() {
    this.field.statusChanges.subscribe(status => {
      this.status = status;
      this.errorsKeys = this.field.errors ? Object.keys(this.field.errors) : [];
    });
  }

  buildReason(field: UntypedFormControl) {

    if (field.errors['maxlength']) {
      return field.errors['maxlength']['requiredLength'];
    } else if (field.errors['minlength']) {
      return field.errors['minlength']['requiredLength'];
    } else if (field.errors['min']) {
      return field.errors['min']['requiredLength'];
    } else if (field.errors['max']) {
      return field.errors['max']['requiredLength'];
    } else if (field.errors['error']) {
      return field.errors['error'];
    } else {
      return null;
    }
  }

}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FieldsetHarukiSelectOption } from '../fieldset-haruki/fieldset-haruki-select-option';

@Component({
  selector: 'mm-alice-keys-config',
  templateUrl: './alice-keys-config.component.html',
  styleUrls: ['./alice-keys-config.component.scss']
})
export class AliceKeysConfigComponent implements OnInit, OnChanges {

  @Input('forms') forms: UntypedFormArray;
  @Input('type') type: 'LICENSE' | 'CMS' = 'CMS';
  @Input('resetForms') resetForms: string;

  @Output('removeForm') removeFormEmitter = new EventEmitter();
  @Output('formsChanged') formsChanged = new EventEmitter();
  @Output('openMediaPicker') openMediaPicker = new EventEmitter();

  notToIndexTypeOptions: FieldsetHarukiSelectOption[] = [
    { label: 'Paginas', value: 'PAGES' },
    { label: 'Mídias', value: 'MEDIAS' },
  ]

  typeOptions: FieldsetHarukiSelectOption[] = [
    { label: 'Mensagem de Sistema', value: 'SYSTEM_MESSAGE' },
    { label: 'Conector Externo', value: 'EXTERNAL_CONNECTOR' },
    { label: 'Excluir Páginas do Index Vetorial', value: 'EXCLUDE_PAGES_FROM_INDEX' },
    { label: 'Não indexar', value: 'NOT_TO_INDEX' },
    { label: 'Avatar Assistente', value: 'ASSISTANT_AVATAR' },
    { label: 'Nome da Assistente', value: 'ASSISTANT_NAME' },
  ];

  externalConnectorTypeOptions: FieldsetHarukiSelectOption[] = [
    { label: 'Google Big Query', value: 'BIG_QUERY' },
  ];

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.resetForms && changes.resetForms.currentValue) {
      let value = changes.resetForms.currentValue;
      this.resetForm(value);
    }
  }

  ngOnInit() {
    if (this.type === 'LICENSE') {
      this.typeOptions.push(
        { label: 'Definição de tabela', value: 'TABLE_DEFINITION' },
        { label: 'Modelo LLM', value: 'MODEL' },
        { label: 'Modelo de Embedding', value: 'MODEL_EMBEDDING' },
        { label: 'Tamanho máximo dos segmentos de indexação', value: 'DOCUMENT_SPLITTER_SEGMENT_SIZE' },
        { label: 'Dimensão do index', value: 'INDEX_DIMENSION' },
        { label: 'Quantidade de items na busca', value: 'SEARCH_RESULTS_SIZE' },
        { label: 'Modelo de LLM mais barato', value: 'CHEAPER_MODEL' },
        { label: 'Modelo de Ferramentas', value: 'TOOLS_MODEL' },
      );
    }
  }
  addNew() {
    this.forms.insert(0, this.formBuilder.group({
      name: ['', Validators.required],
      value: ['', Validators.required],
      type: ['SYSTEM_MESSAGE', Validators.required],
      externalConnector: this.formBuilder.group({
        type: [null, Validators.required],
        datasetName: null,
        datasetDescription: null,
        googleCloudApiKey: null,
        googleCloudProjectId: null
      })
    }));
  }

  removeForm(index: number) {
    this.removeFormEmitter.emit(index);
  }

  resetForm(type: string) {
    this.forms.controls.forEach((form: UntypedFormGroup) => {
      if (form.get('type').value === type) {
        form.reset();
      }
    })
  }

  validateShowValueField(type: string) {
    return !(
      type === 'EXTERNAL_CONNECTOR' ||
      type === 'EXCLUDE_PAGES_FROM_INDEX' ||
      type === 'NOT_TO_INDEX' ||
      type === 'ASSISTANT_AVATAR' ||
      type === 'ASSISTANT_NAME'
    )
  }

  validateShowNameField(type: string) {
    return !(
      type === 'EXTERNAL_CONNECTOR' ||
      type === 'NOT_TO_INDEX'
    )
  }

  emitOpenMediaPicker(form: UntypedFormGroup) {
    this.openMediaPicker.emit(form);
  }
}

import { Component, OnInit, ViewContainerRef, Input, ViewEncapsulation, OnDestroy, DestroyRef, inject } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';

import * as moment from 'moment'

import { ClientService } from '../../../services/client.service';
import { Client } from '../../../interfaces/cliente-interface';
import { AlertController, FieldsetHarukiSelectOption } from 'mm-ui';
import { ReasonsService } from '../../../services/reasons.service';
import { BlockEvents } from '../../../interfaces/blockEvents';
import { dateMask } from "../../../utils/masks";
import { MessageService } from 'primeng/api';
import { AuthService } from '../../../services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { monthYearValidator } from '../../../validators/monthYearValidator';
import { DashboardService } from '../../dashboard/dashboard.service';
import { debounceTime, distinctUntilChanged, first, take } from 'rxjs/operators';
import { saveAs } from 'file-saver'
import { Observable, Subscription } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { User } from '../../../models/user';


@Component({
  selector: 'mm-client-view',
  templateUrl: './client-view.component.html',
  styleUrls: ['./client-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ClientViewComponent implements OnInit, OnDestroy {

  private destroyRef = inject(DestroyRef);


  fielddatemask = new UntypedFormControl('');
  form: UntypedFormGroup;
  id: string;
  dateMask = dateMask

  @Input() userInfo: Array<Client> = [];
  @Input() blockEvents: Array<BlockEvents> = [];

  altered: boolean = false;

  reasonsList: Array<any> = [];

  alertClass: string;
  alertText: string;

  forms: UntypedFormArray = this.formBuilder.array([]);
  keyValueConfig: any[];
  hasAlice = false;

  aliceGoogleConfigForm = this._fb.group({
    projectId: [null],
    location: [null],
    json: [null]
  });
  aliceMessage = false;
  storageMessage = false;

  storageGoogleConfigForm = this._fb.group({
    projectId: [null],
    location: [null],
    json: [null],
    useCdn: [true],
    cdnUrl: [null],
    storagePrivateUrl: [null],
    bucketName: [null],
    credentialsScoped: [null]
  });

  maxFileSizeConfig = this._fb.group({
    items: this._fb.array([]),
    otherContentTypeMaxSize: [35]
  })
  maxFileMessage = false;

  reportTypeOptions: FieldsetHarukiSelectOption[] = [
    {
      label: this._translateService.instant('general.select'),
      value: null
    },
    {
      label: this._translateService.instant('dashboard.view.reports.pageAccess'),
      value: 'pageAccess'
    },
    {
      label: this._translateService.instant('dashboard.view.reports.sessionsT'),
      value: 'sessions'
    },
    {
      label: this._translateService.instant('dashboard.view.reports.sessionsPeak'),
      value: 'sessionsPeak'
    },
    {
      label: this._translateService.instant('dashboard.view.reports.activeUsersPeak'),
      value: 'activeUsersPeak'
    },
    {
      label: this._translateService.instant('dashboard.view.reports.fillingForms'),
      value: 'forms'
    },
    {
      label: this._translateService.instant('dashboard.view.reports.alteredPages'),
      value: 'alteredPages'
    },
    {
      label: this._translateService.instant('dashboard.view.reports.ratingFeedback'),
      value: 'ratingFeedback'
    },
    {
      label: this._translateService.instant('dashboard.view.reports.periodPermission'),
      value: 'periodPermission'
    },
    {
      label: this._translateService.instant('dashboard.view.reports.flowsurveyNodeCreation'),
      value: 'flowsurveyNodeCreation'
    }
  ];

  aliceConfigs: FieldsetHarukiSelectOption[] = [
    { label: "Mensagem do Sistema", value: "SYSTEM_MESSAGE" },
    { label: "Definição de Tabela", value: "TABLE_DEFINITION" },
    { label: "Modelo LLM", value: "MODEL" },
    { label: "Modelo de Embedding", value: "MODEL_EMBEDDING" },
    { label: "Tamanho Máximo de Segmento de indexação", value: "DOCUMENT_SPLITTER_SEGMENT_SIZE" },
    { label: "Dimensão do index", value: "INDEX_DIMENSION" },
    { label: "Modelo de LLM mais barato", value: "CHEAPER_MODEL" },
  ];

  reports: boolean;
  reportType = new UntypedFormControl()
  aliceConfigTypes = new UntypedFormControl();
  reportForm: UntypedFormGroup
  userSearch = new UntypedFormControl();
  formsType = new UntypedFormControl('', Validators.required);
  formType = new UntypedFormControl('', Validators.required);
  convertVideo = new UntypedFormControl();
  isInvalid: boolean;
  loadReport: boolean = false;
  jasperReportsList: Array<any>;
  jasperReportParams: Array<any>;
  jasperReportData;
  userS: any = ''

  formsTypeOptions: any[] = [
    {
      label: this._translateService.instant('general.select'),
      value: null
    },
    {
      label: this._translateService.instant('templates.creation.featureItem.FORM_TEMPLATE'),
      value: 'templateForm'
    },
    {
      label: this._translateService.instant('templates.creation.featureItem.FORM_PAGE'),
      value: 'page?Form'
    }
  ];

  formTypeOptions: any[];
  reportToken: string;
  resp: any;
  notAllowed: boolean = false;
  createdAgents = false;
  chatConverted = false;

  constructor(
    private vcr: ViewContainerRef,
    private clientService: ClientService,
    private route: ActivatedRoute,
    private alertController: AlertController,
    private reasonsService: ReasonsService,
    private router: Router,
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private authService: AuthService,
    private _translateService: TranslateService,
    private _fb: UntypedFormBuilder,
    private _dashboardService: DashboardService,
    private user: User
  ) { }

  ngOnInit() {
    this.altered = this.route.snapshot.params['altered'];
    if (this.altered) {
      this.alertClass = "success";
      this.alertText = "Dados do cliente atualizados com sucesso"
    }
    this.id = this.route.snapshot.params['id'];
    this.clientService.get(this.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((user: any) => {
        this.userInfo.push(user);
        this.blockEvents.push(user.blockEvents.pop());

        this.hasAlice = !!user.modules
          .find(m => m.module.name === 'alice' && m.module.available)

        this.reportAuth(this.id)

        this.listenTypeChange();

      })

    this.reasonsService.reasonsList()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((reasons: any) => this.reasonsList.push(reasons.content))

  }


  reportAuth(id) {
    this.authService.licenseAuth(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (resp: any) => {
          let token = localStorage.getItem('accessToken');
          let config = {
            clientId: this.id,
            email: resp?.client?.adminLogin || resp.client.email,
            token: resp.id
          }
          this.authService.validateLicenseAuthToken(config, token)
            .pipe(takeUntilDestroyed(this.destroyRef))
            .subscribe({
              next: async resp => {

                this.reportToken = resp.accessToken;
                this.user.setReportToken(resp.accessToken);

                if (this.hasAlice) {
                  this.getAliceKeyValueConfig(this.id)
                }

                this.jasperReports();

                if (this.hasAlice) {
                  this.clientService.aliceGetGoogleConfig()
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe({
                      next: (config) => this.aliceGoogleConfigForm.patchValue(config)
                    })

                  this.clientService.getConvertVideoConfig()
                    .pipe(takeUntilDestroyed(this.destroyRef))
                    .subscribe({
                      next: (response: any) => this.convertVideo.patchValue(response.transcriptAudioEnabled, { emitEvent: false }),
                      error: () => console.log('Erro ao buscar configuração para extrair aúdio')
                    })

                  this.convertVideo.valueChanges
                    .pipe(debounceTime(300))
                    .subscribe(value => {
                      this.clientService.saveConvertVideoConfig(value)
                        .pipe(takeUntilDestroyed(this.destroyRef))
                        .subscribe({
                          next: () => console.log('Configuração salva com sucesso para extrair aúdio!'),
                          error: () => console.log('Erro ao salvar configuração para extrair aúdio')
                        })
                    });
                }

                this.getGoogleStorageConfig()

                this.getMaxFileSizeConfig();
              },
              error: err => {
                this.notAllowedforReports(err)
              }
            })
        },
        error: err => {
          this.notAllowedforReports(err)
        }
      })
  }

  getAliceKeyValueConfig(id) {
    this.clientService.getAliceKeyValueConfig(id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((response: any) => {
        this.keyValueConfig = response;
        let forms = []
        response.forEach((config) => {
          forms.push(this.formBuilder.group({
            id: config.id,
            name: [config.name, Validators.required],
            value: [config.value, Validators.required],
            type: [config.type, Validators.required],
            externalConnector: this.formBuilder.group({
              type: config?.externalConnector?.type,
              id: config?.externalConnector?.id,
              datasetName: config?.externalConnector?.datasetName,
              datasetDescription: config?.externalConnector?.datasetDescription,
              googleCloudApiKey: config?.externalConnector?.googleCloudApiKey,
              googleCloudProjectId: config?.externalConnector?.googleCloudProjectId
            })
          }));
        });
        this.forms = this.formBuilder.array(forms);
      });
  }


  blockClient(event) {

    event.preventDefault();


    this.alertController.configure({
      showSelectTag: true,
      selectOptions: this.reasonsList[0],
      title: `Bloquear Cliente ${this.userInfo[0].name}`,
      message: `<p>Você tem certeza que deseja bloquear este cliente?</p>

<p>Lembre-se o bloqueio completo interrompe todas as sessões de usuários logados e bloqueia a utilização de todos os serviços da ferramenta.</p>

<p>Para que este cliente seja bloqueado, você deve listar um motivo para realizar essa ação.</p>`,
      buttons: [
        {
          text: 'Bloquear',
          type: 'primary',
          handler: () => {
            let data = {}
            const reasons: HTMLSelectElement = document.querySelector('#alert-select');
            const reasonId = reasons.options[reasons.selectedIndex].value;

            this.clientService.totalBlock(this.id, reasonId)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(status => {
                this.userInfo[0].totalBlock = status.totalBlock;
                this.blockEvents = [status.blockEvents.pop()];
                this.altered = true;
                this.alertClass = 'success';
                this.alertText = 'Usuário bloqueado com sucesso'

              })

          }
        },
        {
          text: 'Cancelar',
          handler: () => {
            console.log('closed');
          },
          type: 'outline-dark'
        }
      ]
    });
    this.alertController.open();

  }

  unblockClient(event) {

    event.preventDefault();

    this.alertController.configure({
      showSelectTag: true,
      selectOptions: this.reasonsList[0],
      title: `Desbloquear Cliente ${this.userInfo[0].name}`,
      message: `
<p>Você tem certeza que deseja desbloquear este cliente?</p>

<p>Lembre-se o desbloqueio completo ativa todas as sessões de usuários logados e libera a utilização de todos os serviços da ferramenta.</p>

<p>Para que este cliente seja desbloqueado, você deve listar um motivo para realizar essa ação.</p>
`,
      buttons: [
        {
          text: 'Desbloquear',
          type: 'primary',
          handler: () => {

            const reasons: HTMLSelectElement = document.querySelector('#alert-select');
            const reasonId = reasons.options[reasons.selectedIndex].value;
            this.userInfo[0].totalBlock = false;
            this.clientService.totalUnblock(this.id, reasonId)
              .pipe(takeUntilDestroyed(this.destroyRef))
              .subscribe(status => {
                this.altered = true;
                this.alertClass = 'success';
                this.alertText = 'Usuário desbloqueado com sucesso';
              })

          }
        },
        {
          text: 'Cancelar',
          handler: () => {
            console.log('closed');
          }
        }
      ]

    });
    this.alertController.open();

  }

  indexUsers() {
    this.clientService.reindexClientUsers(this.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.altered = true;
          this.alertClass = 'success';
          this.alertText = 'Usuários indexados com sucesso';
        },
        error: (err) => {
          this.alertClass = 'danger';
          this.alertText = 'Não foi possível indexar os usuários';
        }
      });
  }

  indexCMS() {
    this.clientService.reindexClientPageAndMedias(this.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.altered = true;
          this.alertClass = 'success';
          this.alertText = 'Objetos indexados com sucesso';
        },
        error: (err) => {
          this.alertClass = 'danger';
          this.alertText = 'Não foi possível indexar os objetos';
        }
      });
  }

  cleanCache() {
    this.clientService.cleanCache(this.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (res) => {
          this.altered = true;
          this.alertClass = 'success';
          this.alertText = 'Cache limpo com sucesso';
        },
        error: (err) => {
          this.alertClass = 'danger';
          this.alertText = 'Não foi possível limpar o cache';
        }
      });
  }


  deleteMedias() {
    this.clientService.deleteMediasDefinitively(this.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.altered = true;
          this.alertClass = 'success';
          this.alertText = 'Midias removidas com sucesso';
        },
        error: () => {
          this.alertClass = 'danger';
          this.alertText = 'Não foi possível remover as mídias';
        }
      })
  }
  createEmbeddingIndex() {

    if (!!this.userInfo[0].modules.find(m => m.module.name === 'flowsurvey' && m.module.available)) {
      this.clientService.createEmbeddingFlowsurvey().subscribe();
    }

    this.clientService.createEmbeddingIndex(this.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.altered = true;
          this.alertClass = 'success';
          this.alertText = 'Índice de embedding criado com sucesso';
        },
        error: () => {
          this.alertClass = 'danger';
          this.alertText = 'Não foi possível criar o índice de embedding';
        }
      })
  }

  removeForm(i: number) {
    const form = this.forms.at(i).value;

    if (form.id) {
      this.clientService.aliceDeleteKeyConfig(form.id)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.forms.removeAt(i);
            this.messageService.add({ severity: 'success', summary: 'Configuração removida com sucesso', detail: '' });
          },
          error: () => this.messageService.add({ severity: 'error', summary: 'Erro ao remover configuração', detail: '' })
        });
    } else {
      this.forms.removeAt(i);
      return;
    }
  }

  saveConfig() {
    this.clientService.saveAliceKeyValueConfig(this.forms.value, this.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.messageService.add({ severity: 'success', summary: 'Configurações salvas com sucesso', detail: '' }),
        error: (err) => {
          const detail = err?.error?.message === 'Type already exists' || err?.error?.status === 400 ? 'Chave de configuração duplicada.' : '';

          this.messageService.add({ severity: 'error', summary: 'Erro ao salvar configurações', detail })
        }
      });
  }


  showReports() {
    this.reports = true
  }

  clearReportForm() {

    this.reportType.reset();
    this.reportForm = undefined;
    this.reports = false
    this.formsType.reset();
    this.formType.reset();
  }

  configReportForm(value: any): void {
    this.jasperReportParams = []
    if (value == 'pageAccess' || value == 'sessions' || value == 'alteredPages' || value == 'ratingFeedback' || value == 'periodPermission') {
      this.reportForm = this._fb.group({
        initialDate: ['', Validators.required],
        finalDate: ['', Validators.required],
        userId: ''
      })
    } else if ((value == 'sessionsPeak') || (value == 'activeUsersPeak')) {
      this.reportForm = this._fb.group({
        initialDate: ['', [Validators.required, monthYearValidator]]
      })
    } else if (value == 'forms') {
      this.reportForm = this._fb.group({
        initialDate: ['', Validators.required],
        finalDate: ['', Validators.required],
        templateId: [''],
        pageId: ['']

      })
    } else if (value == 'flowsurveyNodeCreation') {
      this.reportForm = this._fb.group({
        initialDate: ['', Validators.required],
        finalDate: ['', Validators.required],
      })
    } else {
      this.jasperReportParams = this.jasperReportsList.filter(rep => rep.id === value);
    }
  }

  jasperReports() {
    this._dashboardService.getJasperReports()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (j_reports: Array<any>) => {
          let select_opts = j_reports.map(rep => { return { label: rep.title, value: rep.id } });
          this.reportTypeOptions = [...this.reportTypeOptions, ...select_opts];
          this.jasperReportsList = j_reports;
        },
        error: err => { console.log({ jasperReportError: err }); }
      })
  }

  listenTypeChange() {
    this.reportType.valueChanges.pipe(distinctUntilChanged(), takeUntilDestroyed(this.destroyRef)).subscribe(value => { console.log({ value }); this.configReportForm(value) })

    this.formsType.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(value => {
        if (value)
          if (value == 'templateForm') {
            this._dashboardService.getTemplateForms(this.reportToken)
              .pipe(
                first(),
                takeUntilDestroyed(this.destroyRef)
              )
              .subscribe((resp: Object[]) => {
                let options = resp.map((opt: any) => { return { 'label': opt.templateTitle, 'value': opt.templateId } });
                this.formTypeOptions = options;

              })
          } else {
            this._dashboardService.getPageForms(this.reportToken)
              .pipe(
                first(),
                takeUntilDestroyed(this.destroyRef)
              )
              .subscribe((resp: Object[]) => {
                let options = resp.map((opt: any) => { return { 'label': opt.pageTitle, 'value': opt.pageId } });
                this.formTypeOptions = options;

              })
          }
      })

    this.formType.valueChanges
      .pipe(
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(value => {
        if (value)
          if (this.formsType.value == 'templateForm') {

            this.reportForm.get('templateId').setValue(value);
            this.reportForm.get('pageId').setValue('');

          } else {

            this.reportForm.get('pageId').setValue(value);
            this.reportForm.get('templateId').setValue('');

          }
      })


  }

  downloadReport() {

    this.loadReport = true;
    let reportType = this.reportType && this.reportType.value ? this.reportType.value : null;
    let reportForm = this.reportForm && this.reportForm.value ? this.reportForm.value : null;

    let reportTypeName
    if (reportType == 'pageAccess') {
      reportTypeName = '_paginas_acessadas'
    } else if (reportType == 'sessions') {
      reportTypeName = '_sessoes'
    } else if (reportType == 'sessionsPeak') {
      reportTypeName = '_Pico_Sessoes_Mes';
      let initialDate = reportForm.initialDate;
      reportForm = {
        month: moment(initialDate, 'MM/YYYY').format('M'),
        year: moment(initialDate, 'MM/YYYY').format('YYYY'),
      }
    } else if (reportType == 'activeUsersPeak') {
      reportTypeName = '_Pico_Usuarios_Nominados';
      let initialDate = reportForm.initialDate;
      reportForm = {
        month: moment(initialDate, 'MM/YYYY').format('M'),
        year: moment(initialDate, 'MM/YYYY').format('YYYY'),
      }
    } else if (reportType == 'forms') {
      if (reportForm.templateId) {
        reportTypeName = '_Formulário_No_Template';
      } else {
        reportTypeName = '_Formulário_Na_Página';
      }
    } else if (reportType == 'alteredPages') {
      reportTypeName = '_Criação_edicao_exclusao_de_paginas';
    } else if (reportType == 'ratingFeedback') {
      reportTypeName = '_Rating_e_feedback_por_periodo';
    } else if (reportType == 'periodPermission') {
      reportTypeName = '_Permissionamento_por_periodo'
    }

    if (reportType == 'sessionsPeak') {
      let summary;

      this._dashboardService.getSessionPickId(reportForm)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (resp: any) => {
            this.getReport(reportType, reportForm, reportTypeName, resp.id);
          },
          error: (err) => {
            this.loadReport = false;
            if (err.status == '404') {
              summary = this._translateService.instant('dashboard.view.reports.noDataForm');
            } else if (err.status == '422') {
              summary = this._translateService.instant('dashboard.view.reports.invalidPeriod');
            } else {
              summary = this._translateService.instant('dashboard.view.reports.unableDownload');
            }
            this.messageService.add({ severity: 'error', summary, detail: '' });

          }
        })

    } else if (this.jasperReportParams && this.jasperReportParams.length > 0) {

      let jrp = this.jasperReportParams.map(v => { return { key: v.key, params: v.params, title: v.title } });
      let data = { ...jrp[0], ...{ params: this.jasperReportData } }

      this._dashboardService
        .downloadJasperReport(this.jasperReportParams[0].id, data)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (file) => {
            this.loadReport = false;
            let fileName = `${this.jasperReportParams[0].title}-${moment().format('DD-MM-YYYY-hh-mm-ss')}.xlsx`;
            saveAs(file.body, fileName)
          },
          error: async (err) => {
            this.loadReport = false;
            let message = err.error.text();
            let summary = "Não foi encontrado relatório para download"

            if (message) {
              err.error.text().then(response => {
                if (response == "Período máximo de 90 dias") {
                  summary = this._translateService.instant('dashboard.view.reports.invalidPeriod');
                  this.messageService.add({ severity: 'error', summary, detail: '' });
                } else {
                  this.messageService.add({ severity: 'error', summary, detail: '' });
                }
              })
                .catch(err => {
                  this.messageService.add({ severity: 'error', summary, detail: '' });
                  console.log(err);
                })
            } else {
              this.messageService.add({ severity: 'error', summary, detail: '' });
            }
          }
        })
    } else if (reportType == 'flowsurveyNodeCreation') {
      this._dashboardService
        .getFlowsurveyNodeCreationReport(reportForm.initialDate, reportForm.finalDate)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (report) => {
            saveAs(report.body, `flowsurvey-node-creation-report-${moment().format('DD-MM-YYYY-hh-mm-ss')}.xlsx`);
            this.loadReport = false;
          },
          error: (err) => {
            this.loadReport = false;
            let summary = this._translateService.instant('dashboard.view.reports.unableDownload');

            const detail = err?.message === 'Invalid period' ? 'Período Inválido' : '';
            this.messageService.add({ severity: 'error', summary, detail });
          }
        });


    } else {
      this.getReport(reportType, reportForm, reportTypeName);
    }

  }

  getReport(reportType, reportForm, reportTypeName, sessionPeakId?) {

    this.loadReport = true;

    this._dashboardService.getReport(reportType, reportForm, sessionPeakId)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (file) => {

          this.loadReport = false;
          let fileName = `Relatório${reportTypeName}-${moment().format('DD-MM-YYYY-hh-mm-ss')}.xlsx`;
          saveAs(file.body, fileName);
          this.clearReportForm();

        },
        error: async (err) => {

          this.loadReport = false;
          let message = JSON.parse(await err.error.text()).message;
          let summary;

          if (reportType == 'pageAcess' || reportType == 'sessions') {
            let moreThenThreeMonths = moment(reportForm.finalDate, 'DD/MM/YYYY').diff(moment(reportForm.initialDate, 'DD/MM/YYYY'), 'days') > 180;
            if (moreThenThreeMonths) {
              summary = this._translateService.instant('dashboard.view.reports.maximunReporting3Months');
            } else {
              summary = this._translateService.instant('dashboard.view.reports.unableDownload');
            }
          }
          else if ((reportType == 'sessionsPeak') || (reportType == 'activeUsersPeak') || (reportType == 'ratingFeedback')) {
            if (message == 'no data in this selected period')
              summary = this._translateService.instant('dashboard.view.reports.noDataPeriod');
            else {
              if (moment().diff(moment(`${reportForm.month}/${reportForm.year}`, "M/YYYY"), "days") < 0) {
                summary = 'Período inválido'
              } else {
                summary = this._translateService.instant('dashboard.view.reports.unableDownload');
              }
            }
          }
          else if (reportType == 'forms') {

            if (message == 'no data in this form on the selected period')
              summary = this._translateService.instant('dashboard.view.reports.noDataForm');
            else if (message == 'invalid period - max permited period are 3 months')
              summary = this._translateService.instant('dashboard.view.reports.invalidPeriod');
            else
              summary = this._translateService.instant('dashboard.view.reports.unableDownload');

          }
          else if (reportType = 'periodPermission') {

            if (message == 'no data in this selected period')
              summary = this._translateService.instant('dashboard.view.reports.thereDataPeriod');
            else if (message == 'invalid period - max permited period are 3 months')
              summary = this._translateService.instant('dashboard.view.reports.invalidPeriod');
            else
              summary = this._translateService.instant('dashboard.view.reports.unableDownload');
          }
          else {
            summary = this._translateService.instant('dashboard.view.reports.unableDownload');
          }
          return this.messageService.add({ severity: 'error', summary, detail: '' });
        }
      })
  };

  setUserId(item) {
    this.userS = item;
    this.reportForm.patchValue({ userId: item.id })
    this.userSearch.reset();
  }

  removeUser() {
    this.userS = null;
    this.reportForm.patchValue({ userId: '' })
    this.userSearch.reset();
  }

  searchUser() {
    return new Observable(observer => {

      let data = {
        query: this.userSearch.value,
        showOff: false,
        showNotChargeable: false,
        showNotViewUser: true,
        size: 20,
        page: 0,
        sort: 'fullName.original'
      }

      this._dashboardService.searchUsers(this.reportToken, data)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (resp) => {
            observer.next(resp.content.map(e => { return { name: e.fullName, id: e.id } }));
          },
          error: (err) => {
            observer.next(null);
          }
        });


    })

  }

  notAllowedforReports(error) {
    console.log(error)
    if (error.status && error.status == '401') {
      this.notAllowed = true;
      this.messageService.add({ severity: 'error', summary: error.message, detail: '' })
    }
  }

  getGoogleStorageConfig() {
    this.clientService.gStorageGetConfig()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (config) => this.storageGoogleConfigForm.patchValue(config)
      })
  }

  saveGoogleStorageConfig() {
    const config = this.storageGoogleConfigForm.value;

    this.clientService.gStorageSaveConfig(config)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.storageMessage = true
      })

  }

  saveAliceGoogleConfig() {
    const config = this.aliceGoogleConfigForm.value;

    this.clientService.aliceSaveGoogleConfig(config)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.aliceMessage = true
      })
  }

  getMaxFileSizeConfig() {
    this.clientService.getUploadMediaMaxFileUploadConfig()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (config) => {
          if (config?.items?.length > 0) {
            const itemsForm = this.maxFileSizeConfig.get('items') as UntypedFormArray;
            config.items.forEach(item => {
              itemsForm.push(this._fb.group({
                id: [item?.id],
                contentType: [item?.contentType],
                maxSize: [item?.maxSize]
              }))
            })
          }

          this.maxFileSizeConfig.patchValue({
            otherContentTypeMaxSize: config?.otherContentTypeMaxSize || 30
          })
        }
      })
  }

  addMaxFileSizeConfig() {
    const form = this.maxFileSizeConfig.get('items') as UntypedFormArray;

    form.insert(0, this._fb.group({
      id: [null],
      contentType: [null],
      maxSize: [30]
    }));
  }

  removeMaxSizeConfig(i) {
    const form = this.maxFileSizeConfig.get('items') as UntypedFormArray;

    form.removeAt(i);
  }

  saveMaxFileSizeConfig() {
    this.clientService.saveUploadMediaMaxFileUploadConfig(this.maxFileSizeConfig.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => this.maxFileMessage = true
      })
  }

  resetAllAliceConfigs() {
    this.aliceGoogleConfigForm.reset();
    this.aliceMessage = false;
    this.clientService.resetAliceConfig()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          if (this.hasAlice) {
            this.forms.reset();
            this.getAliceKeyValueConfig(this.id)
          }
        },
        error: () => this.aliceMessage = false
      })
  }

  resetSpecifiedAliceConfig() {
    let config = this.aliceConfigTypes.value;
    this.clientService.resetAliceSpecificConfig(config)
      .pipe(
        debounceTime(500),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: () => {
          if (this.hasAlice) {
            this.getAliceKeyValueConfig(this.id)
          }
        },
        error: () => this.aliceMessage = false
      })
  }

  recreateAgents() {
    this.createdAgents = false;

    this.clientService.recreateAllAgents().subscribe({
      next: () => this.createdAgents = true,
      error: () => { }
    });
  }

  convertChats() {
    this.chatConverted = false;

    this.clientService.convertChats().subscribe({
      next: () => this.chatConverted = true,
      error: () => { }
    });
  }

  ngOnDestroy(): void {
    localStorage.removeItem('reportToken');
  }
}


import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class User {

  TOKEN_KEY = 'accessToken';
  reportToken: string = null;

  constructor(
    private jwtHelper: JwtHelperService
  ) { }

  getToken(): string {
    return localStorage.getItem(this.TOKEN_KEY);
  }

  saveToken(access_token): boolean {
    if (this.checkToken(access_token)) {
      localStorage.setItem(this.TOKEN_KEY, access_token);
      return true;
    }
    return false;
  }

  deleteToken(): boolean {
    if (this.getToken()) {
      localStorage.setItem(this.TOKEN_KEY, '');
      return true;
    }
    return false;
  }

  setReportToken(token: string) {
    this.reportToken = token;
  }

  getReportToken() {
    return this.reportToken;
  }

  isAuthenticated(): boolean {
    return this.checkToken(this.getToken());
  }

  getTokenExpirationDate() {
    const token = this.getToken();
    return token ? this.jwtHelper.getTokenExpirationDate(this.getToken()) : null;
  }

  getUser() {
    return this.jwtHelper.decodeToken(this.getToken()).user;
  }

  checkRole(expectedRole: string): boolean {
    return this.jwtHelper.decodeToken(this.getToken()).user.role === expectedRole;
  }

  getEmail() {
    const token = this.getToken();
    return token ? this.jwtHelper.decodeToken(token).user.email : null;
  }

  private checkToken(access_token): boolean {
    let valid = false;
    try {
      valid = !this.jwtHelper.isTokenExpired(access_token);
    } catch (e) {
      return false;
    }
    return valid;
  }
}
